.page-details--loader {
  @media screen and (max-width: $tablet) {
    display: none;
  }
}

.page-details--loader_tablet {
  display: none;

  @media screen and (min-width: $tabletMin) and (max-width: $tablet) {
    display: block;
  }
}

.page-details--loader_mobile {
  display: none;

  @media screen and (max-width: $mobile) {
    display: block;
  }
}
