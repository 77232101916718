.accordion--panel {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.07);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template: 'accordionHeader accordionHeader'
    'accordionColumn1 accordionColumn2'
    'accordionFooter accordionFooter';
  overflow: hidden;

  @media screen and (max-width: 1020px) {
    grid-template: 'accordionHeader accordionHeader'
      'accordionColumn1 accordionColumn1'
      'accordionColumn2 accordionColumn2'
      'accordionFooter accordionFooter';
  }
}

.accordion--panel.accordion-enter-active,
.accordion--panel.accordion-enter-done {
  background-color: white;
  height: 320px;

  @media screen and (max-width: $desktop) {
    height: 370px;
  }

  @media screen and (max-width: 420px) {
    height: 460px;
  }
}

.accordion--header,
.accordion--column,
.accordion--footer {
  padding-left: 2rem;
  padding-right: 2rem;
}

.accordion--header {
  grid-area: accordionHeader;
  padding-top: 2rem;
}

.accordion--column {
  overflow-wrap: break-word;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.accordion--column__1 {
  grid-area: accordionColumn1;
}

.accordion--column__2 {
  grid-area: accordionColumn2;
}

.accordion--footer {
  grid-area: accordionFooter;
  padding-bottom: 0;
  padding-top: 1rem;
}

.list--accordion-icon svg {
  height: 15px;
  position: relative;
  top: 2px;
  transition: all 300ms ease-out;
  width: 15px;
}

.accordion--column__blank-state .code {
  margin-top: 0.5rem;
}

.list--row__hovered .list--accordion-icon svg {
  fill: $link;
}
