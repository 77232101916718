.form {
  padding: 3.5rem 0 8rem;
}

.inline-form {
  padding: 1rem 0;
}

.field:not(:last-child) {
  margin-bottom: 2rem;
}

label,
.label {
  color: darken($grey-light, 15%);
  cursor: pointer;
  font-family: $family-sans-serif;
  font-size: 1.125rem;
}

.label:not(:last-child) {
  margin-bottom: 0.5rem;
}

input.input,
textarea.textarea {
  border-radius: 3px;
  font-size: 1.5rem;
  letter-spacing: 1px;
  height: 50px;
  padding: 5px 15px 3px;
  border-bottom: 3px solid #ddd;
  box-shadow: none;
  transition: border-color 200ms ease-in-out;
}

textarea.textarea {
  height: auto;
}

input.input:hover,
input.input:active,
input.input:focus,
textarea.textarea:hover,
textarea.textarea:focus,
textarea.textarea:active {
  border-bottom: 3px solid lighten($link-color, 40%);
}

input.input:active,
input.input:focus,
textarea.textarea:focus,
textarea.textarea:active {
  border: 1px solid #aaa;
  border-bottom: 3px solid $link;
}

label.radio {
  color: $grey;
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-right: 10px;
  width: 90%;
}

.radio-label.is-short {
  width: 200px;
}

.radio-label__other-input {
  margin-left: 40px;
  width: 80%;
}

input[type='radio'] {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

// Bulma override
label.radio,
.radio + .radio {
  margin-left: 10px;
}

.radio-label {
  display: inline-block;
  clear: both;
  font-family: $family-sans-serif;
  font-size: 1rem;
  width: 80%;
  transition: color 200ms ease-in-out;
}

.is-addons-form-toggler-height {
  height: 114px;
  padding-top: 1rem;
}

.is-addons-form-height {
  height: 54px;
}

.field.has-addons {
  background-color: $code-background;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom: 2px solid black;
  max-width: 300px;

  @media screen and (max-width: 330px) {
    max-width: 100%;
  }
}

.tx-in-progress .field.has-addons {
  border-color: $link;
}

.form:not(.tx-in-progress) .field.has-addons:hover path,
.form:not(.tx-in-progress) .field.has-addons:hover .input,
.form:not(.tx-in-progress) .field.has-addons:hover .button,
.form:not(.tx-in-progress) .field.has-addons .input:focus {
  fill: white;
  color: black;
}

.form:not(.tx-in-progress) .field.has-addons:hover svg {
  filter: invert(100%);
}

.field.has-addons.is-right {
  margin: 0 0 0 auto;

  @media screen and (max-width: $tablet) {
    margin-left: 0;
  }
}

.field.has-addons .input.is-large {
  border: 0;
  padding: 0;
  height: 100%;
}

.input {
  box-shadow: none;
  color: $grey-darker;
  font-weight: 300;
}

.input:focus {
  box-shadow: none;
}

.input.is-large {
  background-color: transparent;
  border-radius: 0;
  font-size: 2rem;
  padding: 1.5rem 1rem 1rem;

  @media screen and (max-width: $tablet) {
    max-width: 100%;
    min-width: 100%;
  }
}

.field.has-addons .control:not(:last-child) {
  margin-right: 0;
}

.field.has-addons .button {
  color: $grey;
  font-weight: 900;
  height: 100%;
  margin: 0;
  padding: 1rem 1.5rem;
}

.form:not(.tx-in-progress) .field.has-addons .button:hover,
.form:not(.tx-in-progress) .field.has-addons .button:focus {
  background-color: lighten($grey-lighter, 6%);
}

.form.is-success .control,
.form.is-success .field.has-addons .button:hover {
  background-color: rgba(174, 216, 147, 0.1);
  color: $green;
}

.form.is-success .field.has-addons {
  border-bottom-color: $green;
}

.form.is-danger .field.has-addons {
  border-bottom-color: $red;
}

.form.is-warning .field {
  opacity: 0.4;
}

.form.is-warning .field.has-addons {
  border-bottom-color: $yellow;
}

.field-addon--icon {
  height: 1.375rem;
  margin: 1rem 1rem 0.5rem;
  width: 1.375rem;
}

.field-addon--zep-token-logo path {
  fill: $grey;
}

.field-addon--error-exclamation path {
  fill: $red;
}

.form.is-warning .field-addon--icon path {
  fill: $yellow;
}
