.navbar {
  z-index: 9999;
}

.navbar > .container .navbar-brand,
.container > .navbar .navbar-brand {
  margin-left: 0;
}

.navbar > .container .navbar-menu,
.container > .navbar .navbar-menu {
  margin-right: 0;
}

.navbar-brand {
  @media screen and (max-width: $navbar-breakpoint) {
    z-index: 2000;
    position: relative;
  }
}

.navbar-end .navbar-item {
  border-bottom: 1px solid transparent;
  margin-right: 0.5rem;
  padding: 0 0.1rem;
  margin: 1rem 0;
  transition: border 300ms ease-in-out;

  @media screen and (max-width: $navbar-breakpoint) {
    font-size: 1rem;
  }
}

.navbar-end .navbar-item:hover {
  border-bottom: 1px solid white;
}

.navbar-brand svg.navbar-brand__tertiary-logo {
  margin-left: 3px;
  margin-top: -3px;
  opacity: 0.86;
  transition: opacity 150ms ease-in-out;
  width: 100px;

  @media screen and (max-width: 460px) {
    display: none;
  }
}

.navbar-brand .navbar-item:hover svg.navbar-brand__tertiary-logo {
  opacity: 1;
}

.navbar-brand svg {
  width: 130px;
}

.navbar-brand__secondary-logo {
  color: #75e5ea;
  font-family: $family-sans-serif;
  font-weight: 300;
  font-size: 1.25rem;
  letter-spacing: 0;
  position: relative;
  text-transform: capitalize;
  transition: color 150ms ease-in;
  top: -3px;
}

.navbar-brand__secondary-logo:hover {
  color: white;
}

.navbar-brand svg path {
  transition: fill 150ms ease-in;
}

.navbar-brand svg:hover path {
  fill: white;
}

.navbar-end .navbar-item {
  border-bottom: 1px solid transparent;
  transition: border 300ms ease-in-out;
}

.navbar-end .navbar-item:hover {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.navbar-menu.is-active .navbar-end .navbar-item:hover {
  border-bottom-color: transparent;
}

.navbar.is-dark {
  background-color: darken($navbar-grey, 8%);
}

.navbar.is-dark .navbar-brand > a.navbar-item:hover,
.navbar.is-dark .navbar-brand > a.navbar-item.is-active,
.navbar.is-dark .navbar-brand .navbar-link:hover,
.navbar.is-dark .navbar-brand .navbar-link.is-active,
.navbar.is-dark .navbar-end > a.navbar-item:hover,
.navbar.is-dark .navbar-end > a.navbar-item.is-active,
.navbar.is-dark .navbar-end .navbar-link:hover,
.navbar.is-dark .navbar-end .navbar-link.is-active {
  background-color: transparent;
}

.navbar-item svg:hover {
  transition: transform 150ms ease-in;
}

a.navbar-item,
.navbar-link {
  color: $navbar-item-hover;
}

.navbar.is-dark a.navbar-item,
.navbar.is-dark .navbar-link {
  color: $navbar-dark-item-color;
}

.navbar.is-dark a.navbar-item.is-active,
.navbar.is-dark .navbar-link.is-active {
  color: $navbar-dark-item-hover-color;
}

.navbar-item,
.navbar-link {
  font-family: $code-family;
  font-size: 0.75rem;
  font-weight: 900;
  letter-spacing: 0.05rem;
  padding: 0.5rem 0 0.325rem;
  text-transform: uppercase;
}

.navbar-menu-container {
  position: relative;
}

.navbar-menu {
  @media screen and (max-width: $navbar-breakpoint) {
    display: block;
    height: auto;
    position: absolute;
    text-align: right;
    top: -250%;
    transition: all 700ms cubic-bezier(0.35, 0.49, 0, 1);
    opacity: 0;
    left: -5vw;
    right: -5vw;
    box-sizing: content-box;
    padding: 0 5vw;
  }
}

.navbar-menu.is-active {
  opacity: 1;
  top: 100%;
}

.navbar-menu .navbar-end {
  @media screen and (max-width: $navbar-breakpoint) {
    height: 75%;
    right: 0.75rem;
    width: 100%;
  }
}

.navbar-menu .navbar-item {
  margin-left: 1rem;
}

.navbar.is-dark .navbar-menu {
  background-color: transparent;

  @media screen and (max-width: $navbar-breakpoint) {
    background-color: darken($navbar-dark-background-color, 8%);
  }
}

.nav-background.is-active {
  box-shadow: 0 8px 16px rgba(10, 10, 10, .1);
  cursor: pointer;
  pointer-events: auto;
  z-index: 999;
  opacity: .6;
}

.nav-background {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .2s;
  opacity: 0;
  z-index: 1;
  background: black;
}

.burger {
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  font-size: 9px;
  height: 3em;
  margin-top: 1.25rem;
  margin-left: auto;
  position: relative;
  transition: 0.1s all ease-in-out;
  user-select: none;
  outline: none;
  width: 3em;
  margin-right: 1em;

  @media screen and (min-width: $navbar-breakpointMin) {
    display: none;
  }
}

.burger:after {
  content: "";
  display: block;
  position: absolute;
  height: 150%;
  width: 150%;
  top: -25%;
  left: -25%;
}

.burger .burger-lines {
  top: 50%;
  margin-top: -0.125em;
}

.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
  pointer-events: none;
  display: block;
  content: "";
  width: 100%;
  border-radius: 0.25em;
  background-color: rgba(150, 150, 150, 0.8);
  height: 0.25em;
  position: absolute;
  transform: rotate(0);
}

.burger .burger-lines .burger-lines:after {
  left: 0;
  top: -1em;
}

.burger .burger-lines .burger-lines:before {
  left: 1em;
  top: 1em;
}

.burger:hover {
  transform: scale3d(1, 1, 1);
}

.burger:hover .burger-lines,
.burger:hover .burger-lines:after,
.burger:hover .burger-lines:before {
  background-color: rgba(255, 255, 255, 1);
}

.burger.burger-slip .burger-lines:after, .burger.burger-slip .burger-lines:before {
  width: 2em;
}

.burger.burger-slip .burger-lines, .burger.burger-slip .burger-lines:after, .burger.burger-slip .burger-lines:before {
  transition: all 0.2s;
}

.burger.burger-slip .burger-lines:after {
  left: 0;
  top: -1em;
}

.burger.burger-slip .burger-lines:before {
  left: 1em;
  top: 1em;
}

.burger.burger-slip.open .burger-lines {
  transform: translateX(-2em);
  background-color: transparent;
}

.burger.burger-slip.open .burger-lines:before, .burger.burger-slip.open .burger-lines:after {
  left: 0.5em;
  top: 0px;
}

.burger.burger-slip.open .burger-lines:before {
  transform: translateX(2em) rotate(135deg);
}

.burger.burger-slip.open .burger-lines:after {
  transform: translateX(2em) rotate(-135deg);
}
