.list {
  width: 100%;
}

.list--row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  transition: all 600ms ease-out;
}

.list--cell {
  border: 1px solid #dbdbdb;
  border-width: 0 0 1px;
  display: inline-flex;
  font-size: 0.9rem;
  text-align: left;
  transition: all 600ms ease-out;
}

.list--row_challenge .list--cell {
  @media screen and (max-width: $mobile) {
    border: 0;
  }
}

.list--row.is-active .list--cell {
  border-color: #ececec;
}

.list--cell__head {
  border-bottom-width: 1px;
  border-width: 0 0 2px;
  color: #788899;
  font-family: "Roboto Mono", monospace;
  font-weight: 900;
  letter-spacing: 0.05rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 3px;
  padding-right: 3px;
  text-transform: uppercase;
}

.list--cell.list--cell__head {
  font-size: 0.625rem;
}

.list__has-padding {
  padding: 1rem 0.5rem;
}

.list--row__head .list--cell__head:first-child {
  padding-left: 9px;
}

.list--cell:first-child > * {
  margin-left: 6px;
  padding-right: 10px;

  @media screen and (max-width: $mobile) {
    margin-left: 0;
  }
}

.list__wrapping-anchor {
  background-color: inherit;
  border: none;
  color: inherit;
  cursor: pointer;
  height: 100%;
  font-size: inherit;
  text-align: inherit;
  width: 100%;
}

.list__wrapping-anchor:focus {
  outline: none;
}

.list__wrapping-anchor:hover {
  transform: scale3d(1, 1, 1);
}

.list--row.list--row_challenge:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.03);
}

.list--row.list--row_challenge.is-active {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.list--row.list--row__hovered {
  background-color: darken($white-bis, 2%);
  transition: all 600ms ease-out;
}

.list--row.list--row__blank-state:hover,
.list--row.list--row__head:hover {
  background-color: transparent;
}

.list--row_challenge {
  grid-template-columns: 1fr 120px 100px 70px 40px 100px;

  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr 120px minmax(60px, 80px) 50px 40px 100px;
  }

  @media screen and (max-width: $mobile) {
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;

    grid-template: 'desc desc more more' 'status severity bounty github';
    grid-template-columns: 3fr 1fr 1.5fr 1fr;

    grid-template-rows: auto;
    margin-top: 3rem;

    &:nth-child(2) {
      margin-top: 0;
    }
  }
}

.list--row_challenge .list__has-padding {
  @media screen and (max-width: $mobile) {
    padding: 1rem;
  }
  @media screen and (max-width: 320px) {
    padding: 1rem 0.5rem;
  }
}


.list--row_challenge .desc {
  @media screen and (max-width: $mobile) {
    grid-area: desc;
  }
}

.list--row_challenge .more .list__has-padding,
.list--row_challenge .desc .list__has-padding {
  @media screen and (max-width: $mobile) {
    padding-bottom: 0;
  }
}

.list--row_challenge .status {
  @media screen and (max-width: $mobile) {
    grid-area: status;
  }
}

.list--row_challenge severity {
  @media screen and (max-width: $mobile) {
    grid-area: severity;
  }
}

.list--row_challenge .bounty {
  @media screen and (max-width: $mobile) {
    grid-area: bounty;
  }
}

.list--row_challenge .github {
  @media screen and (max-width: $mobile) {
    grid-area: github;
  }
}

.list--row_challenge .more {
  @media screen and (max-width: $mobile) {
    grid-area: more;
  }
}

.list--row_challenge .github svg {
  @media screen and (max-width: $mobile) {
    margin: 0 0 0 auto;
  }
}

.list--row__head.list--row_challenge {
  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.list--row__two-column {
  grid-template-columns: 495px 70px;

  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr 70px;
  }
}

.list--row__two-column .list__has-padding {
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
}

.list--row__two-column svg {
  height: 21px;
}

.list--row__three-column {
  grid-template-columns: 480px 70px 50px;

  @media screen and (max-width: $widescreen) {
    grid-template-columns: 510px 70px 50px;
  }

  @media screen and (max-width: $desktop) {
    grid-template-columns: 1fr 70px 50px;
  }
}

.list--row__three-column .list__has-padding {
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
}

.list--row__three-column svg {
  height: 21px;
}

.list--cell.break-words {
  word-break: break-all;
}

.list--row__blank-state {
  grid-template-columns: 1fr;
}

.list--cell__blank-state {
  display: block;
  font-size: 1rem;
  padding: 2rem;
  text-align: center;
}
