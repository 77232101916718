.section.has-level {
  border-bottom: 1px solid lighten($text, 60%);
  padding: 0;

  @media screen and (max-width: $tablet) {
    padding: 0;
  }
}

.section.has-level .level {
  @media screen and (max-width: $tablet) {
    display: flex;
  }
}

.section.has-level .level-item .button {
  @media screen and (max-width: $tablet) {
    font-size: 0.75rem;
  }
}

.is-secondary-nav {
  justify-content: center;
  margin: 0 auto;
  max-width: 600px;
  text-transform: uppercase;

  @media screen and (max-width: $tablet) {
    width: 100%;
  }
}

.is-secondary-nav .level-item {
  border-bottom: 2px solid transparent;
  font-size: 0.875rem;
  margin: 0 1rem;
  padding: 1rem 0 0 0;
  position: relative;
  top: 2px;

  @media screen and (max-width: $tablet) {
    padding: 0.5rem 0 0 0;
    margin: 0 0.375rem;
  }
}

.is-secondary-nav .level-item.is-active,
.is-secondary-nav .level-item:hover {
  border-color: $text;
  font-weight: 900;
}

.is-secondary-nav .level-item.is-active button:hover {
  transform: scale3d(1, 1, 1);
}

.is-secondary-nav .level-item .link {
  padding: 0.5rem 0;
}

.is-secondary-nav > .level-item:not(.is-narrow) {
  flex-grow: 0;
}

.level--wrapper {
  margin: 0.325rem 0;
}

.level--vouch:not(:last-child) {
  margin-bottom: 0;
}

.level--vouch .level-item {
  @media screen and (max-width: $tablet) {
    justify-content: initial;
  }
}

.level-left + .level-right {
  @media screen and (max-width: $tablet) {
    margin-top: 0;
  }
}

.level.truncate .level-item {
  @media screen and (max-width: $tablet) {
    text-overflow: ellipsis;
    max-width: 99%;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
}

.level.truncate:not(:last-child) {
  @media screen and (max-width: $tablet) {
    margin-bottom: 1rem;
  }
}
