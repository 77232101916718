.button:focus:not(:active),
.button.is-focused:not(:active) {
  box-shadow: none;
}

.button[disabled],
button[disabled] {
  cursor: not-allowed;
}

.button[disabled]:hover,
.button[disabled]:focus,
.button[disabled]:active,
button[disabled]:hover,
button[disabled]:focus,
button[disabled]:active {
  transform: scale3d(1, 1, 1);
}

.button {
  text-transform: uppercase;
}

.button.is-text {
  border-radius: 0;
  text-decoration: none;
  margin-bottom: calc(0.375em);
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.button.is-text.has-extra-margin {
  margin-left: 0.75em;
  margin-right: 0.75em;

  @media screen and (max-width: $tablet) {
    margin-top: 0.75em;
  }
}

.button.is-text:hover,
.button.is-text:active,
.button.is-text:focus {
  background-color: initial;
}

.button.is-primary {
  background-color: $turqoise;
  color: $button-text-color;
}

.button.is-pill {
  border: 0;
  border-radius: 30px;
  box-shadow: 0px 2px 10px rgba(121, 132, 153, 0.2);
  font-family: $family-code;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.25rem 1.125rem 0.25rem;
  min-height: 2.375rem;
  min-width: 110px;
  transition: all 150ms ease-in-out;

  @media screen and (max-width: $tablet) {
    font-size: 0.875rem;
    min-height: 2.25rem;
    padding: 0.15rem 1.125rem 0.2rem;
  }
}

.button.is-pill:hover {
  box-shadow: 0px 4px 20px rgba(121, 132, 153, 0.3);
  color: $dark;
}

.button.is-pill.is-dark:hover {
  color: white;
}

.button.is-size-5 {
  font-size: 1rem !important;
  min-height: 3rem;
  padding: 1.25rem 3rem 1.325rem;

  @media screen and (max-width: $desktop) {
    font-size: 1rem !important;
  }
}

.button.is-purple {
  background-color: $purple;
  border: none;
  color: white;
}

.button.is-purple:hover {
  background-color: lighten($purple, 10%);
  border: none;
  color: white;
}

.button.is-primary:hover,
.button.is-primary:focus,
.button.is-primary:active {
  background-color: lighten(saturate($turqoise, 100%), 10%);
  color: $button-text-color;
  transition: all 150ms ease-in-out;
}

.is-copy-button {
  color: lighten($text, 30%);
  cursor: pointer;
  position: absolute;
  right: 0.75rem;
  top: 0.625rem;
}

.is-copy-button:hover {
  color: $text;
}

.button-left {
  margin-right: 0.5rem;

  @media screen and (max-width: 382px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.button-right {
  margin-left: 0.5rem;

  @media screen and (max-width: 382px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

a.button,
button {
  transition: all 150ms ease-out;
  transform: scale3d(1, 1, 1);
}

a.button:hover:not(.is-text),
button:hover:not(.is-text) {
  transition: transform 150ms ease-in;
  transform: scale3d(1.04, 1.04, 1.04);
}

.button.is-text.has-underline-border:hover {
  border-bottom-color: $button-text-hover-color;
}
