.hero .hero-body {
  padding-left: 0;
  padding-right: 0;
}

.hero.is-medium .hero-body {
  background-color: #0095e4;
  background-image: linear-gradient(217deg, darken(#29d4ff, 5%) 0%, darken(#29d4ff, 10%) 40%, #0095e4 100%);
  line-height: 2;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 5.5rem;
  padding-top: 5rem;

  @media screen and (max-width: $desktop) {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
}

.hero.is-medium p {
  color: white !important;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.hero.is-dark.is-bold {
  background-color: $hero-dark;
  background-image: none;
}

.hero.is-link {
  background-color: #0095e4;
  background-image: linear-gradient(130deg, #0095e4 0%, darken(#29d4ff, 7%) 80%, darken(#29d4ff, 1%) 100%);
}

.hero--title {
  font-weight: 400;
}

.hero.is-light {
  background-color: $hero-light;
}

.hero--title.content:not(:last-child),
.hero--title.title:not(:last-child),
.hero--title.subtitle:not(:last-child) {
  margin-bottom: 0.8rem;
}

.hero a {
  border-bottom: 1px solid transparent;
  transition: border 300ms ease-in-out;
}

.hero a:hover {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.hero p {
  font-size: 1.125rem;
  margin: 1rem 0;
}

.hero p:first-child {
  margin-top: 0;
}

.hero p:last-child {
  margin-bottom: 0;
}
