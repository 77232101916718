.button.is-text.back-button {
  font-size: 0.875rem;
  margin: 0.5rem 0 0 0;
}

.package-item--maintained-by {
  color: lighten($text, 22%);
  margin-top: -1rem;
  margin-bottom: 0.75rem;
}

.package-item--version {
  margin-left: 0.5rem;
}

.package-item--github-icon {
  color: $grey !important;
  font-size: 23px;
  height: auto;
  margin-left: 0.625rem;
  margin-top: 0.6rem;
  position: relative;
  padding: 0;
  top: 0.25rem;
}

.package-item--github-icon:hover {
  color: black !important;
}

.package-item--description {
  min-height: 2rem;
  margin-bottom: 1.5rem;
  margin-top: 0;
}

.package-item--image {
  margin: 0 0 1.5rem auto;
  height: 109px;
  width: 109px;

  @media screen and (max-width: $tablet) {
    margin-left: 0;
  }
}
